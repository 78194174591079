// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{margin:0;font-family:'SF Pro Text', sans-serif}.vYv51PrBF4PrpYd6\\+4Z0\\+A\\=\\={display:grid;grid-template-columns:256px 1fr;height:100vh}@media (max-width: 800px){.vYv51PrBF4PrpYd6\\+4Z0\\+A\\=\\={grid-template-columns:1fr}}\n", "",{"version":3,"sources":["webpack://./src/styles.scss"],"names":[],"mappings":"AAIA,KACI,QAAS,CACT,qCAAsC,CACzC,8BAIG,YAAa,CACb,+BAAgC,CAChC,YAAa,CAEb,0BALJ,8BAMQ,yBAA0B,CAEjC","sourcesContent":["$mobile: 800px;\n$filterBarHeight: 60px;\n$sidebarWidth: 256px;\n\nbody {\n    margin: 0;\n    font-family: 'SF Pro Text', sans-serif;\n}\n\n\n.app-container {\n    display: grid;\n    grid-template-columns: 256px 1fr;\n    height: 100vh;\n\n    @media (max-width: $mobile) {\n        grid-template-columns: 1fr;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appContainer": "vYv51PrBF4PrpYd6+4Z0+A=="
};
export default ___CSS_LOADER_EXPORT___;
